import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { apiClient } from "../../config/axios";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import PlainHeader from "../../common/PlainHeader";
import ModalField from "../../common/ModalField";
import { TrustContent } from "../../constant/TrustData";
import { DoNotTrustContent } from "../../constant/DonotTrustData";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const MessageIntercepted = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState(null);
  const [showModals, setShowModals] = useState({
    showDonotTrustModal: false,
    showTrustModal: false,
    showRecantModal: false,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (location.pathname.includes("message-intercepted")) {
      const sid = queryParams.get("sid");
      if (sid) {
        setId(sid);
      }
    }
  }, [location]);

  const fetchMessageIntercept = async () => {
    const response = await apiClient.get(`api/short-url/${id}`);
    return response.data;
  };

  const { isLoading, data: messageIntercepted } = useQuery(
    ["message_intercepted", id],
    fetchMessageIntercept
  );

  const trustMessageIntercept = async () => {
    if (!id) {
      const errorMessage = "Short ID is missing.";
      throw new Error(errorMessage);
    }
    try {
      await apiClient.post("api/message-interception/message-intercepted", {
        shortId: id,
        trust: true,
      });
    } catch (error) {
      throw error;
    }
  };

  const trustMutation = useMutation(trustMessageIntercept, {
    onSuccess: () => {
      toast.success("Successfully trusted!");
      navigate("/");
    },
    onError: (error) => {
      toast.error(
        error?.response?.data?.message || error?.message || "An error occurred"
      );
    },
  });

  const dateObj = new Date(messageIntercepted?.urlExpire);
  const monthName = dateObj.toLocaleDateString("en-US", { month: "long" });
  const day = dateObj.getUTCDate() + 2;
  const recepientName = messageIntercepted?.client;

  return (
    <>
      <PlainHeader />
      <div className="flex flex-col font-DMSans lg:px-72 justify-center items-center gap-4 pt-[85px]">
        <div className="relative z-10 max-w-[880px]">
          {isLoading ? (
            <div className="mt-60">
              <Loader />
            </div>
          ) : (
            <div className="Rectangle23 rounded-3xl lg:shadow border border-purple-500 lg:backdrop-blur-2xl pt-[58px] !px-10 pb-[20px] container !z-1 text-[15px]">
              <div className="w-full lg:w-[800px] md:w-[500px] text-[#000] mx-auto">
                This is an automated reply from Recant.ai, an online abuse
                filter that recant_ai uses to filter abusive material.
                <br /> <br />
                Your message was flagged by our AI as abusive and potentially
                criminal. It has been blocked and {recepientName} has not seen
                it.
                <br />
                <br />
                You have until {day}
                {"  "}
                {monthName} to subscribe to our short course below. If you do,
                recant_ai won’t receive your message, and it will disappear
                never to be seen by anyone.
                <br />
                <br />
                If you ignore it, our temporary block will be removed. We don’t
                know what {recepientName} will do with it, but our system
                provides them with several tools including profile owner
                identification tools, legal and police contacts. {recepientName}{" "}
                also has the ability to republish your material.
                <br />
                <br />
                We created this app to help stamp out online abuse and
                harassment, and to give you the opportunity to reconsider your
                actions before it harms others, and ends up harming your
                reputation.
                <br />
                <br />
                Recant.ai Team
              </div>

              <div className="flex flex-wrap gap-3 my-4 justify-center w-full font-poppins">
                <div className="border-primaryColor border-[2px] bg-primaryColor rounded shadow backdrop-blur-lg py-2 px-5 flex-1 min-w-52">
                  <div className="flex items-center justify-center ">
                    <button
                      className="uppercase text-white w-full hover:bg-hoverYellow hover:text-primaryColor hover:border-hoverYellow transition-all duration-300 ease-in-out"
                      onClick={() => setShowModals({ showRecantModal: true })}
                    >
                      Subscribe & Recant
                    </button>
                  </div>
                </div>

                <div className="border-primaryColor border-[2px] rounded shadow backdrop-blur-lg py-2 px-5 hover:bg-primaryColor hover:text-white text-primaryColor flex-1 min-w-52">
                  <div className="relative flex items-center justify-center ">
                    <button
                      onClick={() => setShowModals({ showTrustModal: true })}
                      className="text-center text-base flex uppercase"
                    >
                      Trust & Insist
                    </button>
                  </div>
                </div>

                {/* Tooltip for the "I do not trust" button */}
                {/* <OverlayTrigger
                  placement="top"
                  overlay={(props) => (
                    <Tooltip {...props} className="z-30">
                      This is the option to recant the message, never to be seen
                      again and eliminate all risk of being reported to
                      authorities or any form of publication. This is the simplest
                      option.
                    </Tooltip>
                  )}
                >
                  <div className="border-primaryColor border-[2px] rounded shadow backdrop-blur-lg py-2 px-5 hover:bg-primaryColor hover:text-white text-primaryColor flex-1 min-w-52">
                    <div className="flex items-center justify-center ">
                      <button
                        className="text-center uppercase"
                        onClick={() =>
                          setShowModals({ showDonotTrustModal: true })
                        }
                      >
                        I do not trust
                      </button>
                    </div>
                  </div>
                </OverlayTrigger> */}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal for "I do not trust" */}
      {/* <ModalField
        confirmButtonTitle={"Confirm"}
        modalHeader={"Confirm Catfishing"}
        modalContent={
          "Are you sure you want to report this person as catfishing and want to upload evidence?"
        }
        modalDescription={DoNotTrustContent}
        handleCloseModal={() =>
          setShowModals({ ...showModals, showDonotTrustModal: false })
        }
        isLoading={isLoading}
        showModal={showModals.showDonotTrustModal}
        onClickConfirmButton={() => {
          navigate("/not-trust");
          localStorage.setItem("shortId", JSON.stringify(id));
        }}
      /> */}

      {/* Modal for "Trust & Insist" */}
      <ModalField
        confirmButtonTitle={"Confirm"}
        modalHeader={"Confirm Trust & Insist"}
        modalContent={"Are you sure you know this person?"}
        modalDescription={TrustContent}
        handleCloseModal={() =>
          setShowModals({ ...showModals, showTrustModal: false })
        }
        isLoading={trustMutation.isLoading}
        showModal={showModals.showTrustModal}
        onClickConfirmButton={() => trustMutation.mutate()}
      />

      {/* Modal for "Recant" */}
      <ModalField
        confirmButtonTitle={"Confirm"}
        modalHeader={"Confirm Recant"}
        modalDescription={
          "Recanting your message is the simplest option and provides you with some helpful tools. This includes an optional mini course and the removal of your message to reduce risk of being reported to authorities or any form of publication."
        }
        handleCloseModal={() =>
          setShowModals({ ...showModals, showRecantModal: false })
        }
        isLoading={trustMutation.isLoading}
        showModal={showModals.showRecantModal}
        onClickConfirmButton={() => {
          navigate("/payment-summary");
          localStorage.setItem("shortId", JSON.stringify(id));
        }}
      />
    </>
  );
};

export default MessageIntercepted;
