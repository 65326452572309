import { useState } from "react";
import { toast } from "react-toastify";
import { apiClient } from "../../config/axios";
import { useQueryClient } from "react-query";

const useDisconnectProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const handleDisconnectProfile = async (selectedProfile) => {
    let apiUrl = "";

    switch (selectedProfile?.platform) {
      case "facebook":
        apiUrl = "/api/facebook/disconnect";
        break;
      case "instagram":
        apiUrl = "/api/instagram/disconnect";
        break;
      case "twitter":
        apiUrl = "/api/auth/twitter/disconnect";
        break;
      default:
        toast.error("Unsupported platform:", selectedProfile.platform);
        return;
    }

    setIsLoading(true);
    try {
      await apiClient.delete(apiUrl);
      toast.success("Profile disconnected successfully.");
      setShowModal(false);
      setIsLoading(false);
      queryClient.invalidateQueries("[connectedProfile]");
      queryClient.invalidateQueries("[userPages]");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          "An error occurred while disconnecting profile."
      );
      setIsLoading(false); // Ensure isLoading is set to false on error
    }
  };

  return {
    isLoading,
    showModal,
    setShowModal,
    handleDisconnectProfile,
  };
};

export default useDisconnectProfile;
