import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { useParams, useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { apiClient } from "../../config/axios";
import { useQuery, useMutation } from "react-query";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstLetter";
import Loader from "../../components/Loader";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import ModalField from "../../common/ModalField";
import { toast } from "react-toastify";

const UserProfile = () => {
  const [showToggleOnModal, setShowToggleOnModal] = useState(false);
  const [showToggleOffModal, setShowToggleOffModal] = useState(false);
  const [showToggleOnJobModal, setShowToggleOnJobModal] = useState(false);
  const [showToggleOffJobModal, setShowToggleOffJobModal] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState(null);
  const [selectedJobProfileId, setSelectedJobProfileId] = useState(null);
  const [toggledProfiles, setToggledProfiles] = useState({});
  const [toggledCornJob, setToggledCornJob] = useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  const fetchUserDetail = async () => {
    const response = await apiClient.get(`/api/user/admin/${id}`);
    return response.data;
  };

  const { data, isLoading, error } = useQuery(
    ["userListDetails", id],
    fetchUserDetail,
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );

  useEffect(() => {
    if (data?.user) {
      const profileToggles = data.user.userProfile.reduce((acc, profile) => {
        acc[profile.id] = profile.sendInterceptionMessage;
        return acc;
      }, {});
      setToggledProfiles(profileToggles);
    }
  }, [data]);

  useEffect(() => {
    if (data?.user) {
      const profileToggles = data.user.userProfile.reduce((acc, profile) => {
        acc[profile.id] = profile.enableCronJob;
        return acc;
      }, {});
      setToggledCornJob(profileToggles);
    }
  }, [data]);

  const InterceptMutation = useMutation(
    async (payload) => {
      try {
        await apiClient.put("api/user/admin/user-profile", payload);
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        toast.success("Successfully updated message interception!");
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "An error occurred"
        );
      },
    }
  );
  const EnableCronJobMutation = useMutation(
    async (payload) => {
      try {
        await apiClient.put("api/user/admin/user-profile/cron-job", payload);
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: () => {
        toast.success("Successfully updated cron job!");
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "An error occurred"
        );
      },
    }
  );

  const handleConfirmToggle = (value) => {
    InterceptMutation.mutate({
      id: selectedProfileId,
      sendInterceptionMessage: value,
    });
    setToggledProfiles((prevState) => ({
      ...prevState,
      [selectedProfileId]: value,
    }));
    setShowToggleOnModal(false);
    setShowToggleOffModal(false);
  };

  const handleConfirmJobToggle = (value) => {
    EnableCronJobMutation.mutate({
      id: selectedJobProfileId,
      enableCronJob: value,
    });
    setToggledCornJob((prevState) => ({
      ...prevState,
      [selectedJobProfileId]: value,
    }));
    setShowToggleOnJobModal(false);
    setShowToggleOffJobModal(false);
  };

  if (isLoading) {
    return (
      <AdminLayout>
        <div className="bg-white w-full px-3 pt-3 h-[90.8vh] flex justify-center items-center">
          <div className="loader">
            <Loader />
          </div>
        </div>
      </AdminLayout>
    );
  }

  if (error || !data?.user) {
    return (
      <AdminLayout>
        <div className="bg-white w-full h-[68.8vh] flex justify-center items-center">
          <div className="font-poppins font-[600] text-base lg:!text-xl">
            User not found!
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <>
      <AdminLayout>
        <div className="bg-white w-full px-3 pt-3 h-[90.8vh]">
          <div className="lg:mx-8 mx-4">
            <div
              className="back-btn flex items-center gap-2 border-1 flex-row py-1 px-3 mb-4"
              onClick={() => navigate(-1)}
            >
              <RiArrowLeftLine />
              Back
            </div>
            <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4">
              <div className="flex justify-between">
                <div className="w-[50%]">
                  <h6 className="text-[#000] font-semibold">
                    Personal Information
                  </h6>
                  <div>
                    <div>First Name:</div>
                    <div className="font-semibold">{data.user.firstName}</div>
                  </div>
                  <div className="flex justify-between flex-wrap gap-2 my-4">
                    <div>
                      <div>Last Name:</div>
                      <div className="font-semibold">{data.user.lastName}</div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <div>Email:</div>
                    <div className="font-semibold">{data.user.email}</div>
                  </div>
                  {data.user.userSupport?.length > 0 && (
                    <div className="mb-2">
                      <div>User Support Query:</div>
                      <div className="font-semibold">
                        {data.user.userSupport.map((support, index) => (
                          <li key={index} className="list-disc">
                            {support.description}
                          </li>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className="!mr-[-30px]">
                    <div>User Tolerance:</div>
                    <div className="font-semibold">
                      {data.user.userTolerance}
                    </div>
                  </div>

                  <div className="!mr-[-30px] mt-3">
                    <div>Send Interception Message:</div>

                    {data.user.userProfile.map((profile) => (
                      <div key={profile.id} className="flex items-center my-2">
                        <div className="mr-2">
                          {capitalizeFirstLetter(profile.platform)}
                        </div>
                        <button
                          className={`py-2 font-semibold rounded ${
                            toggledProfiles[profile.id]
                              ? "bg-green-500"
                              : "bg-gray-500"
                          }`}
                          onClick={() => {
                            setSelectedProfileId(profile.id);
                            toggledProfiles[profile.id]
                              ? setShowToggleOffModal(true)
                              : setShowToggleOnModal(true);
                          }}
                        >
                          {toggledProfiles[profile.id] ? (
                            <FaToggleOn fontSize={30} />
                          ) : (
                            <FaToggleOff fontSize={30} />
                          )}
                        </button>
                      </div>
                    ))}
                  </div>
                  <div className="!mr-[-30px] mt-3">
                    <div>Enable Cron Job:</div>

                    {data.user.userProfile.map(
                      (profile) =>
                        profile.platform === "twitter" && (
                          <div
                            key={profile.id}
                            className="flex items-center my-2"
                          >
                            <div className="mr-2">
                              {capitalizeFirstLetter(profile.platform)}
                            </div>
                            <button
                              className={`py-2 font-semibold rounded ${
                                toggledCornJob[profile.id]
                                  ? "bg-green-500"
                                  : "bg-gray-500"
                              }`}
                              onClick={() => {
                                setSelectedJobProfileId(profile.id);
                                toggledCornJob[profile.id]
                                  ? setShowToggleOffJobModal(true)
                                  : setShowToggleOnJobModal(true);
                              }}
                            >
                              {toggledCornJob[profile.id] ? (
                                <FaToggleOn fontSize={30} />
                              ) : (
                                <FaToggleOff fontSize={30} />
                              )}
                            </button>
                          </div>
                        )
                    )}
                  </div>
                  {data.user.hateTopics && data.user.hateTopics.length > 0 && (
                    <div className="w-full mt-4">
                      <div className="mb-4">
                        <div className="mb-2">
                          Your attitude towards hate speech and discriminatory
                          contents:
                        </div>
                        <div className="font-semibold break-words">
                          {data.user.hateTopics.map((topic, index) => (
                            <div key={index}>
                              <>{topic.title} - </>
                              <>{topic.content} </>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4 mt-6">
              <h6 className="text-[#000] font-semibold mb-3">
                Connected User Profiles
              </h6>
              {data.count.length > 0 ? (
                <div className="mb-4 mr-[10px]">
                  {data.count.map((profile, index) => (
                    <div
                      className="flex justify-between w-[100%] md:w-[50%] items-center"
                      key={index}
                    >
                      <div className="mb-4 mr-[10px]">
                        {capitalizeFirstLetter(profile.platform)}
                      </div>
                      <div className="mb-4 mr-[10px]">
                        <p>Total Message Count: {profile.messageCount}</p>
                        <p>
                          Total Message Intercepted:{" "}
                          {profile.interceptedMessageCount}
                        </p>
                        <p>
                          Total Message Recanted: {profile.recantedMessageCount}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="mb-4 mr-[10px]">
                  {data.user.userProfile.length > 0 ? (
                    data.user.userProfile.map((profile, index) => (
                      <div className="flex items-center mb-4" key={index}>
                        <div className="w-full md:w-1/2">
                          {capitalizeFirstLetter(profile.platform)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>No connected user profiles found</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </AdminLayout>
      <ModalField
        confirmButtonTitle={"Confirm"}
        modalHeader={"Confirm Message Interception Turn On"}
        modalContent={"Are you sure you want to turn on message interception?"}
        handleCloseModal={() => setShowToggleOnModal(false)}
        showModal={showToggleOnModal}
        onClickConfirmButton={() => handleConfirmToggle(true)}
      />
      <ModalField
        confirmButtonTitle={"Confirm"}
        modalHeader={"Confirm Message Interception Turn Off"}
        modalContent={"Are you sure you want to turn off message interception?"}
        handleCloseModal={() => setShowToggleOffModal(false)}
        showModal={showToggleOffModal}
        onClickConfirmButton={() => handleConfirmToggle(false)}
      />
      <ModalField
        confirmButtonTitle={"Confirm"}
        modalHeader={"Confirm Enable Corn Job"}
        modalContent={"Are you sure you want to enable Corn Job?"}
        handleCloseModal={() => setShowToggleOnJobModal(false)}
        showModal={showToggleOnJobModal}
        onClickConfirmButton={() => handleConfirmJobToggle(true)}
      />
      <ModalField
        confirmButtonTitle={"Confirm"}
        modalHeader={"Confirm Disable Corn Job"}
        modalContent={"Are you sure you want to disable Corn Job?"}
        handleCloseModal={() => setShowToggleOffJobModal(false)}
        showModal={showToggleOffJobModal}
        onClickConfirmButton={() => handleConfirmJobToggle(false)}
      />
    </>
  );
};

export default UserProfile;
