import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import PaymentModal from "../PaymentModal";
import { useQuery } from "react-query";
import { apiClient } from "../../config/axios";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentModalVisible, setPaymentModalVisible] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-received`,
      },
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        toast.error(error.message);
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setPaymentModalVisible(false);
  };

  const formData = {
    firstName: localStorage.getItem("firstName"),
    lastName: localStorage.getItem("lastName"),
    email: localStorage.getItem("email"),
    shortId: JSON.parse(localStorage.getItem("shortId")),
    amount: Number(localStorage.getItem("amount")),
  };

  const fetchCheckout = async () => {
    const response = await apiClient.post(`api/checkout`, formData);
    return response.data;
  };

  // Use 'enabled' in the correct context where it's passed inside the useQuery options.
  useQuery("checkout", fetchCheckout, {
    enabled: Boolean(paymentModalVisible), // Ensure enabled is a boolean value
    onError: (error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    },
  });

  return (
    <>
      <div className="z-10 max-w-[570px]">
        <div className="rounded-3xl shadow border border-purple-500 backdrop-blur-2xl pt-[42px] px-10 pb-[20px]">
          <div className="w-full ">
            <div className="text-2xl font-bold mb-10 text-[#5F6388] flex items-center">
              Shipping Details
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <PaymentElement
              onFocus={() => {
                setPaymentModalVisible(false);
              }}
            />

            <button
              disabled={isLoading || !stripe || !elements}
              id="submit"
              className="bg-primaryColor rounded-xl text-white w-[100%] py-[10px] mt-6 mb-2 font-bold text-[16px] hover:bg-hoverPrimary"
            >
              <span id="button-text">
                {isLoading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    className="mr-[5px]"
                  />
                ) : (
                  "Pay now"
                )}
              </span>
            </button>
          </form>
        </div>
      </div>
      {paymentModalVisible && <PaymentModal closeModal={closeModal} />}
    </>
  );
};

export default CheckoutForm;
