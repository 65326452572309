import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { logoutUser } from "../../../config/useAuth";
import { HiMiniUserCircle } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../../components/DataContext";
import { RiApps2Fill } from "react-icons/ri";
import { HiOutlineLogout } from "react-icons/hi";
import { useTheme, useMediaQuery } from "@mui/material";
import WWYMSButton from "../../../components/LandingPage/WWYMSButton";

const TopNavBar = ({ color }) => {
  const navigate = useNavigate();
  const currentUrl = useLocation();
  const { user } = useUser();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className="w-full flex items-center justify-between pt-[10px]   z-10 cursor-pointer ">
      <div
        className={`flex items-center ${
          color ? "text-primaryColor" : "text-baseColor"
        }`}
      >
        {isDesktop && (
          <span
            className={`mr-3 font-bold ${
              color ? "text-primaryColor" : "text-baseColor"
            }`}
          >
            {" "}
            {user?.firstName && `Hi, ${user?.firstName}`}
          </span>
        )}
        {currentUrl?.pathname !== "/login" &&
          currentUrl?.pathname !== "/signup" && (
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                <HiMiniUserCircle
                  className={`object-contain w-10 h-10 ${
                    color ? "text-primaryColor" : "text-baseColor"
                  }`}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="px-10 w-48  !left-[-290%] ">
                {currentUrl?.pathname !== "/dashboard" &&
                  currentUrl?.pathname !== "/login" && (
                    <div
                      className="dropdown-item !flex items-center gap-2 "
                      href="#"
                      onClick={() => navigate("/dashboard")}
                    >
                      <RiApps2Fill /> Dashboard
                    </div>
                  )}

                {user && (
                  <div
                    className="dropdown-item !flex items-center gap-2 !text-[#606060]"
                    href="#"
                    onClick={logoutUser}
                  >
                    <HiOutlineLogout /> Logout
                  </div>
                )}
                <div className="dropdown-item !flex items-center gap-2  ">
                  <a
                    href="https://wwyms.recant.ai/"
                    className="!text-[#606060] no-underline"
                  >
                    WWYMS
                  </a>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          )}
      </div>
    </div>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="flex items-center justify-center w-9 h-9 rounded-full "
  >
    {children}
  </span>
));

export default TopNavBar;
