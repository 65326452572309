import React, { useState, useEffect, useRef } from "react";
import FileUpload from "../../common/FileUpload";
import { useForm } from "react-hook-form";
import PlainHeader from "../../common/PlainHeader";
import { useMutation } from "react-query";
import { apiClient } from "../../config/axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { NotTrustSchema } from "../../validation/doNotTrustValidation";
import { z } from "zod";
import ButtonField from "../../common/ButtonField";

const DoNotTrust = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [photosError, setPhotosError] = useState("");
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
  const [denyAllegationShortId, setDenyAllegationShortId] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const queryString = currentUrl.split("?")[1];

    if (queryString) {
      const params = new URLSearchParams(queryString);
      const paramNames = Array.from(params.keys());
      setDenyAllegationShortId(paramNames[0]);
    }
  }, [location]);

  const dynamicSchema = NotTrustSchema.extend({
    photos: z.string().optional(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(dynamicSchema),
  });

  useEffect(() => {
    if (uploadedFiles.length === 0 && submitButtonClicked) {
      setPhotosError("Photos is required");
    } else {
      setPhotosError("");
    }
  }, [uploadedFiles, submitButtonClicked]);

  const onSaveSubmit = (data) => {
    if (uploadedFiles.length === 0) {
      setPhotosError("Photos is required");
      return;
    }

    const shortId = JSON.parse(localStorage.getItem("shortId"));
    const formData = new FormData();

    uploadedFiles.forEach((file) => {
      formData.append("photos", file);
    });
    formData.append(
      "shortId",
      denyAllegationShortId ? denyAllegationShortId : shortId
    );
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    mutation.mutate(formData);
  };
  const alegationType = denyAllegationShortId ? "lodge-dispute" : "donot-trust";

  const mutation = useMutation(
    (formData) =>
      apiClient.post(
        `/api/message-interception/donot-trust?type=${alegationType}`,

        formData
      ),
    {
      onSuccess: () => {
        toast.success("Allegation submitted successfully");
        navigate("/");
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
    }
  );

  return (
    <div>
      <PlainHeader />
      <div className="pt-[70px]">
        <form
          onSubmit={handleSubmit(onSaveSubmit)}
          className="py-10 sm:px-4 md:px-10 xl:px-64 flex flex-col gap-3"
        >
          <div className="px-2">
            <div className="flex flex-wrap gap-4 mb-4">
              <div className="flex-1">
                <label>User Name:</label>
                <input
                  name="username"
                  {...register("username")}
                  placeholder="Enter User Name"
                  className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
                />
                {errors?.username?.message && (
                  <p style={{ color: "#CC2828", marginTop: "5px" }}>
                    {errors?.username?.message}
                  </p>
                )}
              </div>
              <div className="flex-1">
                <label>Email:</label>
                <input
                  name="email"
                  {...register("email")}
                  placeholder="Enter Email"
                  className="h-[38px] w-[100%] rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2 py-4"
                />
                {errors?.email?.message && (
                  <p style={{ color: "#CC2828", marginTop: "5px" }}>
                    {errors?.email?.message}
                  </p>
                )}
              </div>
            </div>

            <div>
              <div className="mb-2">Upload the Evidence</div>
              <FileUpload
                onFilesChange={setUploadedFiles}
                photosError={photosError}
              />
            </div>
            <div>
              <div className="mb-2">
                Please provide an explanation of what took place. Please note
                this could be used as evidence in legal proceedings, so be sure
                to be 100% honest.{" "}
              </div>
              <textarea
                {...register("message")}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
              {errors?.message?.message && (
                <p style={{ color: "#CC2828", marginTop: "5px" }}>
                  {errors?.message?.message}
                </p>
              )}
            </div>
            <ButtonField
              label={"SUBMIT"}
              isLoading={mutation.isLoading}
              onClick={() => setSubmitButtonClicked(true)}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default DoNotTrust;
