import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/RecantNewLogo.png";
import { logoutUser } from "../../config/useAuth";

export default function ResponsiveDrawer({
  open,
  handleDrawerToggle,
  sideBarItems,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer
      variant="temporary"
      open={open}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true,
      }}
      classes={{
        paper: "bg-gray-900 text-white",
      }}
    >
      <div className="flex justify-between items-center p-4">
        <a className="nav-logo  cursor-pointer " onClick={() => navigate("/")}>
          <img src={logo} width={70} />
        </a>
        <IconButton color="inherit" onClick={handleDrawerToggle}>
          <CloseIcon className="text-black" />
        </IconButton>
      </div>
      <Divider className="bg-gray-600" />
      <List>
        {sideBarItems?.map((item, index) => (
          <ListItem
            key={index}
            button
            to={item.link !== "/logout" && item.link}
            component={Link}
            onClick={item.link === "/logout" && logoutUser}
            className={`flex  items-center p-2 !rounded-lg transition-colors duration-200  !text-baseColor ${
              location.pathname == item.link
                ? "!bg-[#cbc9c9] text-base"
                : "hover:bg-gray-100"
            }`}
          >
            {item.icon && (
              <ListItemAvatar>
                <Avatar sx={{ color: "inherit", bgcolor: "transparent" }}>
                  <item.icon />
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              primary={item.title}
              className={`${!item.icon && "ml-4"}`}
            />
          </ListItem>
        ))}
        {/* {["Dashboard", "Users", "Settings"].map((text) => (
          <ListItem button key={text} className="hover:bg-gray-800">
            <ListItemText primary={text} className="text-black" />
          </ListItem>
        ))} */}
      </List>
    </Drawer>
  );
}
