import React from "react";
import Modal from "react-bootstrap/Modal";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstLetter";
import Loader from "../../components/Loader";
import { useLocation } from "react-router-dom";

const ModalField = ({
  onClickConfirmButton,
  handleCloseModal,
  isLoading,
  showModal,
  selectedProfile,
  modalHeader,
  modalContent,
  confirmButtonTitle,
  modalDescription,
}) => {
  const location = useLocation();

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      centered
      className="flex items-center justify-center z-50"
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>{modalHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="font-semibold mb-1">{modalContent}</p>
        {location.pathname === "/user-information" && (
          <p>
            <strong>Platform:</strong>{" "}
            {capitalizeFirstLetter(selectedProfile?.platform)}
          </p>
        )}
        {modalDescription && (
          <p
            dangerouslySetInnerHTML={{ __html: modalDescription }}
            className="text-[14px] mt- text-[#717070]"
          ></p>
        )}
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none" }}>
        <div className="flex items-center justify-center gap-3 font-semibold">
          <button
            onClick={handleCloseModal}
            className="px-3 py-2 bg-[#929090] rounded-[2px] text-white hover:bg-[#626161] transition-colors duration-300"
          >
            Cancel
          </button>

          <div className="relative w-full flex items-center justify-center">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center right-3">
                <Loader color={" #105620"} />
              </div>
            )}
            <button
              onClick={onClickConfirmButton}
              disabled={isLoading}
              className={`py-2 px-4 bg-[#28a745] text-white rounded-[2px] ${
                isLoading ? "cursor-not-allowed opacity-50" : ""
              } hover:bg-[#21933c] transition-colors duration-300`}
            >
              {confirmButtonTitle}
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalField;
