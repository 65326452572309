import { Navigate } from "react-router-dom";
export const useAuth = () => {
  //getting token from local storage
  const user = localStorage.getItem("token");
  //checking whether token is preset or not
  if (user) {
    return true;
  } else {
    return false;
  }
};

export const logoutUser = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  <Navigate to="/" replace={true} onClick={window.location.reload()} />;
  if (window.history && window.history.pushState) {
    window.history.pushState("", null, "./");
    window.onpopstate = function () {
      window.history.pushState("", null, "./");
    };
  }
};
