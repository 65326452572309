import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AddProfile.css";
import FacebookLoginButton from "../../Facebook/facebook-login";
import OnBoardingLayout from "../../../layouts/OnBoardingLayout";
import TwitterLoginButton from "../../Twitter/twitter-login";
import { apiClient } from "../../../config/axios";
import { instagramIcon } from "../../../assets/icons/icons";
import Instagram from "../../Instagram/instagram";
import { FaSquareXTwitter } from "react-icons/fa6";
import { ImLinkedin2 } from "react-icons/im";
import { FaFacebook } from "react-icons/fa";
import { useUser } from "../../DataContext";
import { useTwitterProfileStatus } from "../../../hooks/useTwitterProfileStatus";
import { useFacebookProfileStatus } from "../../../hooks/useFacebookProfileStatus";
import { useInstagramProfileStatus } from "../../../hooks/useInstagramProfileStatus";
import { useQueryClient } from "react-query";
import Loader from "../../Loader";

const AddProfile = () => {
  const navigate = useNavigate();
  const [connectLinkedinStatus] = useState(false);
  const token = localStorage.getItem("token");
  const { user } = useUser();
  const {
    data: connectTwitterStatus,
    error: twitterError,
    isLoading: isConnectTwitterLoading,
  } = useTwitterProfileStatus();
  const {
    data: connectFBStatus,
    error: facebookError,
    isLoading: isConnectFacebookLoading,
  } = useFacebookProfileStatus();
  const {
    data: connectInstagramStatus,
    error: instagramError,
    isLoading: isConnectInstagramLoading,
  } = useInstagramProfileStatus();
  const queryClient = useQueryClient();

  const handleClick = () => {
    window.location.href = `https://www.facebook.com/v18.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APPID}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${process.env.REACT_APP_INSTAGRAM_REDIRECT_URI}&response_type=token&scope=instagram_manage_messages,instagram_basic,pages_manage_metadata,pages_read_engagement,pages_show_list,pages_messaging`;
  };

  const connectInstagram = (accessToken) => {
    apiClient
      .post(
        `/api/instagram/connect-instagram`,
        {
          accessToken,
        },
        {
          headers: {
            token,
          },
        }
      )
      .then(() => {
        queryClient.invalidateQueries("[instagramProfileStatus]");
        navigate({
          ...navigate,
          hash: "",
        });
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Could not add profile");
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = urlParams.get("access_token");
    const long_lived_token = urlParams.get("long_lived_token");

    if (accessToken) {
      const token = long_lived_token ? long_lived_token : accessToken;
      connectInstagram(token);
    }
  }, []);

  const handleLoginSuccess = (response) => {
    apiClient
      .post(`api/facebook/connect-facebook`, {
        facebookId: response.userID,
        accessToken: response.accessToken,
        email: response.email,
        expiresIn: response.expiresIn,
      })
      .then(() => {
        toast.success("profile successfully added!");
        navigate("/facebook/addpage");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Could not add profile");
      });
  };

  const handleLoginFailure = () => {
    toast.error("Login failed");
  };

  const handleTwitterLogin = async () => {
    apiClient
      .get(`api/auth/twitter`)
      .then((res) => {
        window.location.href = res.data.url;
        // setConnectTwitterStatus(res.data.success);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || "Something went wrong");
      });
  };

  // const getLinkedinProfileStatus = () => {
  //   apiClient
  //     .get(`api/linkedin/connect-profile-status`)
  //     .then((res) => {
  //       setConnectLinkedinStatus(res.data.success);
  //       return res.data.success;
  //     })
  //     .catch((error) => {
  //       console.log("something went wrong", error);
  //     });
  // };

  return (
    <OnBoardingLayout selected={1}>
      <div className="bg-white w-full px-3 pt-3 h-screen">
        {/* <h1 className="text-grey text-2xl font-sans font-semibold mt-[55px] ">
          Dashboard
        </h1> */}
        <div className="flex flex-col items-center mt-24 md:w-8/12 w-full mx-auto ">
          <h1 className="text-2xl text-grey text-center font-semibold">
            Welcome {user?.firstName} !
          </h1>
          <p className="text-lg text-grey -mt-1 text-center">
            Add social media profiles to your Recant.ai account.
          </p>
        </div>
        <div className="">
          <div className="flex flex-col mt-4 items-center">
            <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-8 mt-4 cursor-pointer">
              {/* facebook Connection */}
              {isConnectFacebookLoading ? (
                <div className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba]">
                  <Loader />
                </div>
              ) : !connectFBStatus ? (
                <FacebookLoginButton
                  onLoginSuccess={handleLoginSuccess}
                  onLoginFailure={handleLoginFailure}
                  className="w-full"
                />
              ) : (
                <>
                  <button
                    className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba]"
                    onClick={() => navigate("/facebook/addpage")}
                  >
                    <div className="flex items-center justify-between w-full ">
                      <FaFacebook className="text-[#34589d]" size={21} />
                    </div>
                    <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2  pb-10">
                      Facebook connected
                    </p>
                  </button>
                </>
              )}

              {/* Instagram Connection */}
              {!connectInstagramStatus ? (
                <span onClick={connectFBStatus ? handleClick : undefined}>
                  <Instagram
                    className="w-full"
                    connectFBStatus={connectFBStatus}
                  />
                </span>
              ) : (
                <button
                  className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba]"
                  onClick={() => navigate("/instagram/business-profile")}
                >
                  <div className="flex items-center justify-between w-full ">
                    <img
                      src={instagramIcon}
                      alt="facebook-icon"
                      className="w-5 h-5"
                    />
                  </div>
                  <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2  pb-10 ">
                    Instagram connected
                  </p>
                </button>
              )}

              {/* Twitter Connection */}
              {!connectTwitterStatus ? (
                <TwitterLoginButton
                  className="w-full"
                  handleTwitterLogin={handleTwitterLogin}
                />
              ) : (
                <button
                  className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba] "
                  onClick={() => navigate("/twitter/profiles")}
                >
                  <div className="flex items-center justify-between w-full">
                    <FaSquareXTwitter size={21} className="" />
                  </div>
                  <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2  pb-10 ">
                    Twitter connected
                  </p>
                </button>
              )}

              {/* Linkdin Connection */}
              <div className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba]">
                <div className="flex items-center justify-between w-full">
                  <ImLinkedin2 className="text-[#017bb8]" size={20} />
                </div>
                <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2  pb-10 ">
                  {!connectLinkedinStatus
                    ? "Linkedin (Coming soon)"
                    : "Linkedin Connected"}
                </p>
              </div>
            </div>
          </div>
          {/* /* add button
{/* <div className="my-4 mt-8 bg-[#E0E4FF] px-4 py-[10px] rounded-full md:w-5/12 w-10/12 flex items-center justify-between">
            <p className="text-grey !mb-0 font-semibold">Add Another</p>
            <span className="w-8 h-8 bg-purple rounded-full flex items-center justify-center cursor-pointer">
              <img src={addWhiteIcon} className="w-2 h-2" />
            </span>
          </div> */}
          {/* <div className="ap-card">
          <div className="card-inner">
          {!connectFBStatus ? (
          <FacebookLoginButton
          onLoginSuccess={handleLoginSuccess}
          onLoginFailure={handleLoginFailure}
          />
          ) : (
          "Connected with facebook"
          )}
          </div> */}
          {/* <div className="card-inner">
          <img src={FbImg} alt=""/>
          <img src={AddIcon} alt=""/>
          <Linkedin connectLinkedinStatus={connectLinkedinStatus} />

          <div
          className="fb-send-to-messenger"
          messenger_app_id="156744197330354"
          data-ref="your-data-ref"
          data-size="large"
          data-color="blue"
          data-callback="sendToMessengerCallback"
          ></div>
          </div> */}
          {/* </div> */}
          {/* <div className="ap-card">
          <div className="card-inner">
          {!connectInstagramStatus ? (
          <InstagramLoginButton
          connectInstagramStatus={connectInstagramStatus}
          />
          ) : (
          "Connected with instagram"
          )}
          </div>
          </div> */}
          {/* <div className="ap-card">
          <div className="card-inner">
          <Linkedin
          className="Linkedin-btn"
          connectLinkedinStatus={connectLinkedinStatus}
          />
          <div
          className="fb-send-to-messenger"
          messenger_app_id={process.env.REACT_APP_FACEBOOK_APPID}
          data-ref="your-data-ref"
          data-size="large"
          data-color="blue"
          data-callback="sendToMessengerCallback"
          ></div>
          </div>
          </div> */}
          {/* <div class="fb-share-button" data-href="https://backend.recant.ai/linkedin" data-layout="button_count" data-size="large"><a target="_blank" href="http://backend.recant.ai/linkedin" class="fb-xfbml-parse-ignore" rel="noreferrer">Share</a></div> */}
        </div>
      </div>
    </OnBoardingLayout>
  );
};

export default AddProfile;
