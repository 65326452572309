import React from "react";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";

const TwitterLoginButton = ({ handleTwitterLogin }) => {
  return (
    <span onClick={handleTwitterLogin}>
      <div className="rounded-[10px] border-[1px] border-[#e2dfdf] flex flex-col items-center pt-2 px-3  w-full hover:border-[#bcbaba] ">
        <div className="flex items-center justify-between w-full">
          <FaSquareXTwitter size={21} className="" />
          <FaPlus className="text-[#787575] " size={21} />
        </div>
        <p className="text-[17px] text-[#787575] font-medium ml-4 !mb-0 px-2 pt-2  pb-10 ">
          Connect to Twitter
        </p>
      </div>
    </span>
  );
};

export default TwitterLoginButton;
