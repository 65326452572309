import React, { useEffect, useState } from "react";
import Intercom from "@intercom/messenger-js-sdk";

import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { SignUp, Login, Verified } from "./components/Auth";
import AddProfile from "./components/Dashboard/AddProfile/AddProfile";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import {
  MessageIntercepted,
  PageNotFound,
  SupportUser,
  TermsCondition,
  SensitivityParameter,
  MessageAllegation,
  DoNotTrust,
  PaymentSummary,
  FaceBookAddPage,
  FaceBookConnectedProfile,
  InstagramConnectedProfile,
  TwitterConnectedProfile,
  FacebookMessages,
  InstagramMessages,
  TwitterMessages,
  Home,
  UserProfile,
  UserList,
  AdminWaitingList,
  AdminWaitingListDetail,
  AdminProfile,
  PaymentSuccessful,
  MessageInterceptTrust,
  DenyAllegation,
  AboutUs,
  LandingPage,
} from "./pages";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ProtectedRoute } from "../src/utils/ProtectedRoute";
import {
  PrivacyPolicy,
  WaitingList,
  Payment,
  UserInformation,
} from "../src/components/index";
import { useAuth } from "./config/useAuth";
import { UserProvider } from "./components/DataContext";
import VerifyEmail from "./components/Auth/VerifyEmail";
import CheckoutUserList from "./pages/Admin/Checkout";
import CheckoutUserDetail from "./pages/Admin/CheckoutDetails";
import InfluencerInformation from "./components/InfluncerInformation";
import { apiClient } from "./config/axios";

function App() {
  const user = useAuth();
  const [connectedProfile, setConnectedProfile] = useState(null);
  const [error, setError] = useState(null);

  const fetchUserDetails = async () => {
    try {
      const { data } = await apiClient.get("api/user");
      setConnectedProfile(data);
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  Intercom({
    app_id: "lmdb7o6i",
    user_id: user.id,
    name: user.name,
    email: user.email,
    created_at: user.createdAt,
  });
  const router = createBrowserRouter([
    {
      path: "/login",
      element: user ? <Navigate to="/dashboard" /> : <Login />,
    },
    {
      path: "/signup",
      element: user ? <Navigate to="/dashboard" /> : <SignUp />,
    },

    {
      path: "/payment-summary",
      element: <PaymentSummary />,
    },
    {
      path: "/404",
      element: <PageNotFound />,
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/payment-received",
      element: <PaymentSuccessful />,
    },
    {
      path: "/waiting-list",
      element: <WaitingList />,
    },
    {
      path: "/individual-survey",
      element: <InfluencerInformation />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/message-intercepted",
      element: <MessageIntercepted />,
    },

    {
      path: "/terms",
      element: <TermsCondition />,
    },
    {
      path: "/message-filter",
      element: <MessageInterceptTrust />,
    },
    {
      path: "/not-trust",
      element: <DoNotTrust />,
    },
    {
      path: "/message-allegation",
      element: <MessageAllegation />,
    },
    {
      path: "/response-allegation",
      element: <DenyAllegation />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/verified",
      element: <Verified />,
    },
    {
      path: "/verify",
      element: <VerifyEmail />,
    },
    {
      path: "/payment-checkout",
      element: <Payment />,
    },
    {
      path: "/sensitivity-parameter",
      element: <SensitivityParameter />,
    },

    {
      key: "protected",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/facebook/addpage",
          element: <FaceBookAddPage />,
        },
        {
          path: "/twitter/profiles",
          element: <TwitterConnectedProfile />,
        },
        {
          path: "/dashboard",
          element: <AddProfile />,
        },
        {
          path: "/twitter/:platformId/messages",
          element: <TwitterMessages />,
        },
        {
          path: "/facebook/business-profile",
          element: <FaceBookConnectedProfile />,
        },
        {
          path: "/instagram/business-profile",
          element: <InstagramConnectedProfile />,
        },
        {
          path: "/instagram/messages/:businessId",
          element: <InstagramMessages />,
        },
        {
          path: "/facebook/messages/:businessId",
          element: <FacebookMessages />,
        },
        {
          path: "/user-information",
          element: <UserInformation />,
        },
        {
          path: "/support",
          element: <SupportUser />,
        },
        {
          path: "/preferences",
          element: <SensitivityParameter />,
        },
        {
          path: "/admin-user",
          element: <UserList />,
        },
        {
          path: "/admin-profile",
          element: <AdminProfile />,
        },
        {
          path: "/admin-checkout",
          element: <CheckoutUserList />,
        },
        {
          path: "/admin",
          element: <Home />,
        },
        {
          path: "/admin-user/:id",
          element: <UserProfile />,
        },
        {
          path: "/admin-checkout/:id",
          element: <CheckoutUserDetail />,
        },
        {
          path: "/admin-waiting-list",
          element: <AdminWaitingList />,
        },
        {
          path: "/admin-waiting-list/:id",
          element: <AdminWaitingListDetail />,
        },
      ],
    },
  ]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <ToastContainer />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </UserProvider>
    </>
  );
}

export default App;
