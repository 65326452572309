import Loader from "../../../components/Loader";

const SendMessageBox = ({
  handleSendValue,
  inputMessage,
  setInputMessage,
  loading,
}) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendValue();
    }
  };

  return (
    <div className="fixed bottom-0 lg:left-[339px] left-[60px] right-0 md:left-[120px]">
      <div className="flex flex-row bg-[#eef5f8] p-[18px]">
        <div className="relative w-full">
          <div className="flex flex-row justify-between items-center p-[12px] rounded-full bg-white w-full text-grey relative">
            <div className="flex flex-row gap-2 items-center w-full">
              <input
                name="Add a message"
                placeholder="Add a message"
                className="outline-none w-full"
                value={inputMessage}
                onChange={(event) => setInputMessage(event.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="absolute right-[7px] top-[6px] ">
              <div className="flex flex-row items-center h-9 w-16 rounded-full bg-primaryColor text-white hover:bg-hoverPrimary ">
                <div className="relative w-full flex items-center justify-center">
                  {loading && (
                    <div className="absolute inset-0 flex items-center justify-center right-[5px]">
                      <Loader color={"#fff"} size={"text-2xl"} />
                    </div>
                  )}
                  <button
                    onClick={handleSendValue}
                    disabled={loading}
                    className={` ${
                      loading ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessageBox;
