import React from "react";
import { useNavigate } from "react-router-dom";

const MainBanner = () => {
  const navigate = useNavigate();

  return (
    <div className="   bg-[#e0e0e0]  ">
      <div className="py-[150px] lg:!pt-[250px]  max-w-[1440px] mx-auto  px-[15px] lg:px-[60px]">
        <h1 className="font-poppins text-5xl font-bold leading-[1.3] mb-[24px]">
          End online abuse.
        </h1>
        <div className="text-lg font-poppins">
          Introducing your private inbox assistant to filter abuse and hold
          offenders accountable. Recant.ai seamlessly protects recipients and
          removes discomfort from social media moderation
          {/* <span className="text-baseColor">
            chat content moderation software
          </span>{" "} */}
        </div>
        <div className="text-lg font-poppins mt-[20px] lg:mt-[20px] ">
          Join our waitlist now to be the first to onboard this new social media
          technology.
        </div>
        <div className="flex items-end mt-[20px] lg:mt-[30px] ">
          <button
            className="  lg:px-7 px-6 py-[9px] rounded-[24px]   font-poppins text-sm lg:!text-lg bg-baseColor text-white hover:bg-hoverBaseColor"
            onClick={() => navigate("/waiting-list")}
          >
            JOIN WAITING LIST
          </button>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
