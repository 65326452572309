import React from "react";
import PaymentCompletedCard from "../../common/PaymentCompletedCard";
import { Link } from "react-router-dom";
import PlainHeader from "../../common/PlainHeader";

const PaymentSuccessful = () => {
  return (
    <>
      <PlainHeader />
      <div className="flex flex-col font-DMSans  lg:px-72 px-14 pb-14 pt-20 justify-center items-center gap-4 ">
        <div className=" relative z-10 max-w-[700px]">
          <div className="Rectangle23  rounded-3xl shadow border border-purple-500 backdrop-blur-2xl pt-[33px] px-10 pb-4">
            <PaymentCompletedCard />
            <Link to="/signup" className="no-underline">
              <button className="bg-yellow flex py-2 px-5 text-white justify-center rounded shadow  w-fit m-auto my-3 uppercase ">
                Go To Sign Up
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccessful;
