import React from "react";
import { NavLink } from "react-router-dom";
import { RiApps2Fill, RiSendPlaneFill } from "react-icons/ri";
import { FaUser } from "react-icons/fa";
import { MdOutlineContactSupport } from "react-icons/md";

export const onBoardingSidebaritems = [
  {
    key: 1,
    link: "/dashboard",
    icon: RiApps2Fill,
  },
  {
    key: 3,
    link: "/user-information",
    icon: FaUser,
  },
  {
    key: 4,
    link: "/support",
    icon: MdOutlineContactSupport,
  },

  // showMessagesItem && {
  //   key: 2,
  //   link: link,
  //   content: <RiSendPlaneFill className={`w-5 h-5`} />,
  // },

  // {
  //   key: 3,
  //   link: "/404",
  //   content: <RiNotification4Fill className={`w-5 h-5`} />,
  // },
  // {
  //   key: 4,
  //   link: "/404",
  //   content: <RiSpyFill className={`w-5 h-5 `} />,
  // },
  // {
  //   key: 5,
  //   link: "/404",
  //   content: <RiCopperCoinFill className={`w-5 h-5`} />,
  // },
  // {
  //   key: 6,
  //   link: "/404",
  //   content: <RiChatDeleteFill className={`w-5 h-5`} />,
  // },
  // {
  //   key: 7,
  //   link: "/404",
  //   content: <RiBarChart2Fill className={`w-4 h-4`} />,
  // },
].filter(Boolean);

const LeftSideBar = ({ selected }) => {
  const currentPlatform = window.location.pathname.includes("facebook")
    ? "facebook"
    : "instagram";
  const showMessagesItem = window.location.pathname.includes("/messages");
  const link = `/${currentPlatform}/messages`;

  return (
    <div className="left-sidebar fixed top-0 left-0 h-full flex justify-center items-center bg-[#eef5f8] w-16">
      <ul className="!p-0 flex justify-center gap-2 flex-col mb-20">
        {onBoardingSidebaritems?.map((item, i) => (
          <li key={i} className="flex justify-center mt-4">
            {" "}
            <NavLink
              to={item.link}
              className="flex justify-center items-center p-0 w-9 h-9 rounded-full text-primaryColor "
            >
              <item.icon />
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LeftSideBar;
