export const TrustContent = `
  By insisting on sending the abuse, I claim to trust the recipient on the basis that this recipient did solicit the message or we are otherwise on mutually agreeable terms, which will in turn notify the recipient. The recipient can then either:
  <br />

  1. verify this claim and whitelist sender, meaning we will not intercept this user anymore. 
  <br />

  2. deny this claim, and I will receive the a 3x multiplied fee for this not to escalate to further action 
        including police reports, lawyer reviews and possible publication.   
  <br />I acknowledge the risk of being reported to authorities for hurtful crimes & any publication including newspaper reports should I end up charged or convicted, or otherwise reported on in order to warn the public about my poor behavior.
`;
