import React, { useState } from "react";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiClient } from "../../../config/axios";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SignUpSchema } from "../../../validation/signUpValidation";
import Loader from "../../Loader";
import { FiEye, FiEyeOff } from "react-icons/fi";
import PlainHeader from "../../../common/PlainHeader";

const SignUp = () => {
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(SignUpSchema),
  });

  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const onSignUpSubmit = async (data) => {
    const updatedSignUpData = {
      email: data?.email,
      password: data?.password,
      firstName: data?.firstName,
      lastName: data?.lastName,
      termsAndConditions: data?.terms_and_conditions,
    };
    try {
      setLoading(true);
      const res = await apiClient.post(
        `/api/auth/registerUser`,
        updatedSignUpData
      );
      if (res.data) {
        toast.success(`${res.data.message}`);

        navigate("/verify");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message || "An error occurred");
    }
  };

  const eyeIcon = <FiEye />;

  return (
    <>
      <PlainHeader />
      <div className="signup-container pt-36">
        <div className="login-wrapper">
          <div className="flex flex-col items-center">
            <form
              className="signup-form"
              onSubmit={handleSubmit(onSignUpSubmit)}
            >
              <h3 className="mb-16 flex items-center justify-center ">
                Join Beta
              </h3>
              <div className="row ">
                <div className="col-6 mb-3">
                  <label className="mb-[5px]">First Name</label>
                  <input
                    type="name"
                    name="firstName"
                    {...register("firstName")}
                    placeholder="Enter First Name"
                    className="h-[38px] w-[100%] rounded-[100px] px-[12px] bg-[#f3f3f0] text-[14px]"
                  />
                </div>
                <div className="col-6 mb-3">
                  <label className="mb-[5px]">Last Name</label>
                  <input
                    type="name"
                    name="lastName"
                    {...register("lastName")}
                    placeholder="Enter Last Name"
                    className="h-[38px] w-[100%] rounded-[100px] px-[12px] bg-[#f3f3f0] text-[14px]"
                  />
                </div>

                <div className="col-12 mb-3">
                  <label className="mb-[5px]">Email</label>
                  <input
                    type="email"
                    name="email"
                    {...register("email")}
                    placeholder="Enter Email"
                    className="h-[38px] w-[100%] rounded-[100px] px-[12px] bg-[#f3f3f0] text-[14px]"
                  />
                  {errors?.email?.message && (
                    <p className="pt-2 mb-0" style={{ color: "#CC2828" }}>
                      {errors?.email?.message}
                    </p>
                  )}
                </div>

                <div className="col-6 mb-3">
                  <label className="mb-[5px]">Password</label>
                  <div className="icon-wrapper">
                    <input
                      className="h-[38px] w-[100%] rounded-[100px] px-[12px] bg-[#f3f3f0] text-[14px]"
                      name="password"
                      {...register("password")}
                      type={passwordType}
                      placeholder="* * * * * *"
                    />
                    <span className="eye-icon" onClick={() => togglePassword()}>
                      {passwordType === "password" ? <FiEyeOff /> : <FiEye />}
                    </span>
                  </div>
                  {errors?.password?.message && (
                    <p className="pt-2 mb-0" style={{ color: "#CC2828" }}>
                      {errors?.password?.message}
                    </p>
                  )}
                </div>
                <div className="col-6 mb-3">
                  <label className="mb-[5px]">Confirm Password</label>
                  <div className="icon-wrapper">
                    <input
                      className="h-[38px] w-[100%] rounded-[100px] px-[12px] bg-[#f3f3f0] text-[14px]"
                      name="confirmPassword"
                      {...register("confirmPassword")}
                      type={confirmPasswordType}
                      placeholder="* * * * * *"
                    />
                    {errors?.confirmPassword?.message && (
                      <p className="pt-2 mb-0" style={{ color: "#CC2828" }}>
                        {errors?.confirmPassword?.message}
                      </p>
                    )}
                    <span
                      className="eye-icon"
                      onClick={() => toggleConfirmPassword()}
                    >
                      {confirmPasswordType === "password" ? (
                        <FiEyeOff />
                      ) : (
                        <FiEye />
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-[10px] mt-3">
                <div className="flex   gap-2">
                  <input
                    type="checkbox"
                    className="cursor-pointer "
                    {...register("terms_and_conditions")}
                  />{" "}
                  <p
                    className={`signup-para mt-[1px]]${
                      errors?.terms_and_conditions?.message && "!text-[#CC2828]"
                    }`}
                  >
                    By registering an account, I accept all the{" "}
                    <a
                      href="/terms"
                      className={`${
                        errors?.terms_and_conditions?.message
                          ? "!text-[#CC2828]"
                          : !"text-primaryColor"
                      }  mx-1 text-primaryColor`}
                    >
                      Terms & Conditions
                    </a>
                    and
                    <a
                      href="/privacy-policy"
                      className={`${
                        errors?.terms_and_conditions?.message
                          ? "!text-[#CC2828]"
                          : !"text-primaryColor"
                      }  mx-1 text-primaryColor`}
                    >
                      Privacy Policy
                    </a>{" "}
                    .
                  </p>
                </div>
                <div className="flex ">
                  <div>
                    {errors?.terms_and_conditions?.message && (
                      <p className="pt-2 mb-0" style={{ color: "#CC2828" }}>
                        {errors?.terms_and_conditions?.message}
                      </p>
                    )}
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="relative w-full flex items-center justify-center">
                    {loading && (
                      <div className="absolute inset-0 flex items-center justify-center right-3 top-3">
                        <Loader />
                      </div>
                    )}
                    <button
                      type="submit"
                      disabled={loading}
                      className={`login-btn  flex justify-center items-center bg-primaryColor hover:bg-ho ${
                        loading ? "cursor-not-allowed opacity-50" : ""
                      }`}
                    >
                      Register
                    </button>
                  </div>
                </div>
                <div className="signup-para mt-[10px]">
                  If you already have an account you can <br />
                </div>
                <div
                  onClick={() => navigate("/login")}
                  className="text-[#1e1f1e] font-bold text-[14px] mb-5 cursor-pointer hover:underline"
                >
                  Login here!
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
