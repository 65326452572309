import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CssBaseline,
  useTheme,
  useMediaQuery,
  Avatar,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle } from "@mui/icons-material";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ResponsiveDrawer from "./../../components/Admin/Drawer";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/recantLogoWhite.png";
import HomeIcon from "@mui/icons-material/Home";
import { logoutUser } from "../../config/useAuth";
import PaidIcon from "@mui/icons-material/Paid";

export const Sidebar_Items = [
  { title: "Home", icon: HomeIcon, link: "/admin" },
  { title: "Users", icon: PeopleIcon, link: "/admin-user" },
  { title: "Profile", icon: PersonIcon, link: "/admin-profile" },
  {
    title: "Waiting List",
    icon: HourglassBottomIcon,
    link: "/admin-waiting-list",
  },
  {
    title: "Checkout List",
    icon: PaidIcon,
    link: "/admin-checkout",
  },
  // { title: "Help", icon: HelpCenterIcon, path: "/admin/help" },
];

function AdminLayout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const location = useLocation();

  useEffect(() => {
    if (isDesktop) {
      setMobileOpen(false);
    }
  }, [isDesktop]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleUserMenuClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null); // Close popover if already open
    } else {
      setAnchorEl(event.currentTarget); // Open popover
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-menu" : undefined;

  return (
    <div className="flex font-DMSans">
      <CssBaseline />
      <AppBar position="fixed" className="z-10 !bg-primaryColor">
        <Toolbar>
          <div className="flex justify-between w-full items-center py-[11px]">
            {!isDesktop ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className="mr-2"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Typography variant="h6" noWrap>
                <a href={"/"}>
                  <img src={Logo} className="w-[100px] mt-[17px] " />
                </a>
              </Typography>
            )}
            <div>
              <IconButton
                aria-describedby={id}
                onClick={handleUserMenuClick}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={() => setAnchorEl(null)}
              >
                <List>
                  <ListItem button component={Link} to={"/admin"}>
                    <ListItemText primary="Home" />
                  </ListItem>
                  <ListItem button component={Link} to={"/admin-profile"}>
                    <ListItemText primary="My account" />
                  </ListItem>
                  <ListItem button onClick={logoutUser}>
                    <ListItemText primary="Logout" />
                  </ListItem>
                </List>
              </Popover>
            </div>
          </div>
        </Toolbar>
      </AppBar>

      <nav className={isDesktop ? "w-60" : ""} aria-label="mailbox folders">
        {isDesktop ? (
          <div className="mt-14 p-3 fixed h-full">
            <div className="w-60 bg-gray-800 h-full border-r border-[#e6e3e3] pt-[40px]">
              <List component="nav" sx={{ px: 2 }}>
                {Sidebar_Items.map((item, index) => (
                  <ListItem
                    key={index}
                    button
                    to={item.link}
                    component={Link}
                    className={`flex items-center p-2 !rounded-lg transition-colors duration-200 ${
                      location.pathname === item.link ||
                      location.pathname.startsWith(item.link + "/")
                        ? "!bg-primaryColor text-white"
                        : "hover:bg-gray-100"
                    }`}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ color: "inherit", bgcolor: "transparent" }}>
                        <item.icon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.title} />
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        ) : (
          <ResponsiveDrawer
            open={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            sideBarItems={Sidebar_Items}
          />
        )}
      </nav>
      <main
        style={{
          flexGrow: 1,
          marginTop: 54,
          paddingLeft: "20px",
          paddingRight: "20px",
        }}
        className={` ${isDesktop && "!ml-[55px]"} !font-open-sans`}
      >
        <Toolbar />
        {children}
      </main>
    </div>
  );
}

export default AdminLayout;
