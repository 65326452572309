const Solution = () => {
  return (
    <div className="md:!px-[90px] sm:!px-[60px] lg:!px-0  pt-[20px] lg:pt-[60px]">
      <div className=" flex flex-col !items-center justify-center mb-12 ">
        <div className="lg:text-3xl font-semibold font-poppins lg:!mb-12 mb-8 ">
          Solution
        </div>
        <div className="text-[#223443] text-lg  lg:!text-xl font-DMSans text-center">
          <span className=" cursor-pointer font-medium">Recant.ai</span>{" "}
          intercepts abusive messages and holds miscreants accountable.
        </div>
      </div>
    </div>
  );
};

export default Solution;
