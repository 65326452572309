import { useQuery } from "react-query";
import { apiClient } from "../config/axios";

const fetchInstagramProfileStatus = async () => {
  const { data } = await apiClient.get("api/instagram/connect-profile-status");
  return data?.success;
};

export const useInstagramProfileStatus = () => {
  return useQuery("[instagramProfileStatus]", fetchInstagramProfileStatus, {
    staleTime: 1000,
  });
};
