import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import OnBoardingLayout from "../../layouts/OnBoardingLayout";
import { capitalizeFirstLetter } from "../../utils/CapitalizeFirstLetter";
import Loader from "../Loader";
import useDisconnectProfile from "../DisconnectProfile";
import { apiClient } from "../../config/axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ModalField from "../../common/ModalField";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserInformation = () => {
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const nagivate = useNavigate();
  const { isLoading, showModal, setShowModal, handleDisconnectProfile } =
    useDisconnectProfile();
  const handleCloseModal = () => setShowModal(false);

  const handleShowModal = (profile) => {
    setSelectedProfile(profile);
    setShowModal(true);
  };
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  const { register, handleSubmit } = useForm({});

  const onSaveSubmit = (data) => {
    console.log(data, "data");
  };

  const fetchUserDetails = async () => {
    const { data } = await apiClient.get("api/user");
    return data;
  };

  const {
    data: connectedProfile,
    error,
    isLoading: connectedProfileLoading,
  } = useQuery("[connectedProfile]", fetchUserDetails, {
    staleTime: 1000,
  });

  useEffect(() => {
    error && toast.error(error?.response?.data?.message || "An error occurred");
  }, [error]);

  return (
    <OnBoardingLayout selected={1}>
      <div className="bg-white w-full px-3 pt-3 pb-6 ">
        <h1 className="text-grey text-2xl font-sans font-semibold mt-[55px] flex items-center justify-center">
          User Profile
        </h1>
        <div className="lg:!mx-48  sm:mx-2 mt-6">
          <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4">
            <h6 className="text-[#000] font-semibold mb-4">
              Personal Information
            </h6>
            <form
              className="flex flex-wrap "
              onSubmit={handleSubmit(onSaveSubmit)}
            >
              {/* First Column */}
              <div className="w-full md:w-1/2 ">
                <div className="mb-4">
                  <div>First Name:</div>
                  {editPersonalInfo ? (
                    <input
                      name="firstName"
                      {...register("firstName")}
                      placeholder="Enter FirstName"
                      className="h-[38px] w-full rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2"
                    />
                  ) : (
                    <div className="font-semibold break-words">
                      {connectedProfile?.firstName}
                    </div>
                  )}
                </div>
                <div className="mb-4">
                  <div>Email:</div>
                  <div className="font-semibold break-words">
                    {connectedProfile?.email}
                  </div>
                </div>
                <div className="mb-4">
                  <div>Role:</div>
                  <div className="font-semibold break-words">
                    {connectedProfile?.role}
                  </div>
                </div>
              </div>

              {/* Second Column */}
              <div className="w-full md:w-1/2 ">
                <div className="mb-4">
                  <div>Last Name:</div>
                  {editPersonalInfo ? (
                    <input
                      name="lastName"
                      {...register("lastName")}
                      placeholder="Enter LastName"
                      className="h-[38px] w-full rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2"
                    />
                  ) : (
                    <div className="font-semibold break-words">
                      {connectedProfile?.lastName}
                    </div>
                  )}
                </div>

                <div>
                  <div>User Tolerance:</div>
                  <button
                    className="px-3 py-2 bg-primaryColor text-white rounded-[10px] mt-2 hover:bg-hoverPrimary"
                    onClick={() => nagivate("/sensitivity-parameter")}
                  >
                    Adjust sensitivity settings
                  </button>
                  {/* {editPersonalInfo ? (
                    <select
                      className="form-select w-full"
                      aria-label="Default select example"
                      defaultValue={"moderate"}
                      {...register("userTolerance")}
                    >
                      {USER_TOLERANCE?.map((profile) => (
                        <option key={profile.value} value={profile.value}>
                          {profile.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <div className="font-semibold break-words">
                      {connectedProfile?.userTolerance}
                    </div>
                  )} */}
                </div>
              </div>

              {/* Full Width Row for Hate Topics */}
              {connectedProfile?.hateTopics &&
                connectedProfile?.hateTopics.length !== 0 && (
                  <div className="w-full mt-4">
                    <div className="mb-4">
                      <div className="mb-2">
                        Your attitude towards hate speech and discriminatory
                        contents:
                      </div>
                      {editPersonalInfo ? (
                        <input
                          name="hateTopics"
                          {...register("hateTopics")}
                          placeholder="Enter Hate Topics"
                          className="h-[38px] w-full rounded-[10px] px-[12px] bg-[#f3f3f0] text-[14px] mt-2"
                        />
                      ) : (
                        <div className="font-semibold break-words">
                          {connectedProfile?.hateTopics.map((topic) => (
                            <div>
                              <>{topic?.title} - </>
                              <>{topic?.content} </>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </form>
          </div>
          <div className="border-[1px] border-[#e6dede] rounded-[8px] p-4 mt-6">
            <h6 className="text-[#000] font-semibold mb-6">
              Connected User Profiles
            </h6>
            {connectedProfileLoading ? (
              <Loader />
            ) : connectedProfile?.userProfile?.length !== 0 ? (
              <div className="">
                {connectedProfile?.userProfile?.map((profile, index) => (
                  <div className="flex  items-center mb-4" key={index}>
                    {/* First Column (Platform) */}
                    <div className=" w-full md:w-1/2 ">
                      {capitalizeFirstLetter(profile?.platform)}
                    </div>
                    {/* Second Column (Edit Connection) */}
                    <div className=" w-full md:w-1/2   ">
                      <button
                        onClick={() => handleShowModal(profile)}
                        className=" px-3 py-2 bg-[#f44336]  md:mb-0 text-white rounded-[2px] font-semibold"
                      >
                        Edit {isDesktop && "Connection"}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div>No connected profiles</div>
                <Link to="/dashboard">
                  <button className="px-3 py-2 bg-primaryColor mb-2 text-white rounded-[2px] mt-3 font-semibold hover:bg-hoverPrimary">
                    Connect Profile
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
        <ModalField
          confirmButtonTitle={"Disconnect"}
          modalContent={
            "Are you sure you want to disconnect the following profile?"
          }
          modalHeader={"Confirm Profile Deletion"}
          handleCloseModal={handleCloseModal}
          isLoading={isLoading}
          showModal={showModal}
          onClickConfirmButton={() => handleDisconnectProfile(selectedProfile)}
          selectedProfile={selectedProfile}
        />
      </div>
    </OnBoardingLayout>
  );
};

export default UserInformation;
