import Navbar from "../LandingPage/Navbar/Navbar";
import { PRIVACY_TERMS } from "../../constant/PrivacyTerms";
import { termsAndConditionFormattedContent } from "../../utils/termsAndConditionFormattedContent";
import PlainHeader from "../../common/PlainHeader";

const PrivacyPolicy = () => {
  return (
    <>
      <PlainHeader />
      <div className="!text-baseColor ">
        <div className="container pt-36 pb-6">
          <h5 className="mb-6 flex font-extrabold">Privacy Policy</h5>
          <section className="flex flex-col gap-3">
            {PRIVACY_TERMS?.map((item, outerIndex) => {
              return (
                <div key={outerIndex} className="flex flex-col gap-2">
                  <h6 className="font-bold">
                    {outerIndex + 1}. {item.title}
                  </h6>

                  <div className="ml-3">
                    {termsAndConditionFormattedContent(item.content)}
                  </div>
                </div>
              );
            })}
          </section>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
