import React, { useState } from "react";
import LeftSideBar from "./LeftSideBar";
import Header from "./Header";
import { useTheme, useMediaQuery } from "@mui/material";
import ResponsiveDrawer from "../../components/Admin/Drawer";
import { onBoardingSidebaritems } from "./LeftSideBar";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import TopNavBar from "./TopNavBar";
import logo from "../../assets/images/RecantNewLogo.png";

const OnBoardingLayout = ({ selected, children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("1024"));
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div>
      <div className="flex py-2 justify-between px-16 fixed bg-white border-b-[2px] border-greyLight w-full z-50 items-center">
        <div className=" lg:!mt-[13px] flex gap-3">
          {!isDesktop ? (
            <RxHamburgerMenu
              className="text-primaryColor mt-1"
              size={23}
              onClick={handleDrawerToggle}
            />
          ) : (
            <a
              className="nav-logo text-yellow  cursor-pointer "
              onClick={() => navigate("/")}
            >
              <img src={logo} width={120} />
            </a>
          )}
        </div>
        <div>
          <TopNavBar />
        </div>
      </div>

      <div className=" w-full  flex ">
        {isDesktop ? (
          <LeftSideBar selected={selected} />
        ) : (
          <ResponsiveDrawer
            open={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            sideBarItems={onBoardingSidebaritems}
          />
        )}
        <div
          className={`flex flex-col w-full ${
            isDesktop && "pl-16"
          } bg-[#f8f1e6] pt-[73px]`}
        >
          <div className="w-full  ">{children}</div>
          {/* <RightSideBar /> */}
          {/* <div className="bottom-nav fixed bottom-0 bottom-bar-shadow z-50 mb-[40px]">
            <div
              className="w-full text-center flex justify-center"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <RiArrowDropUpFill className="text-2xl h-12 text-grey" />

              <div className={isBottomNavVisible ? "bot-nav show" : "bot-nav"}>
                <BottomNav />
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OnBoardingLayout;
