import React from "react";

const RangeSlider = ({ className, color, min, max, value, onChange }) => {
  const adjustLeftPosition = (value) => {
    const percentage = ((value - min) / (max - min)) * 100;
    if (value == 1 || value == 2 || value == 3) {
      return `calc(${percentage}% - (-4px))`;
    }
    return `calc(${percentage}% - 6px)`;
  };

  return (
    <div className={`${className} relative`}>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        className="w-full relative z-20"
        style={{ accentColor: color }}
      />
      <div
        className="absolute font-poppins text-white bg-primaryColor rounded-full"
        style={{
          left: adjustLeftPosition(value),
          transform: "translateX(-50%)",
          bottom: "31px",
          padding: "1px 8px",
          zIndex: 20,
          whiteSpace: "nowrap",
          pointerEvents: "none",
        }}
      >
        {value}
      </div>
      <div className="flex justify-between font-poppins text-sm mt-2">
        <div>Open</div>
        <div>Strict</div>
      </div>
    </div>
  );
};

export default RangeSlider;
