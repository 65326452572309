import {
  Avatar,
  TablePagination,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PersonIcon from "@mui/icons-material/Person";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const headCells = [
  { id: "user", align: "left", label: "User" },
  { id: "userName", align: "center", label: "UserName" },
  { id: "role", align: "center", label: "Role" },
  { id: "status", align: "center", label: "Status" },
];

export const users = [
  {
    id: 1,
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    gender: "Male",
    role: "User",
    disabled: false,
  },
  {
    id: 2,
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com",
    gender: "Female",
    role: "User",
    disabled: false,
  },
  {
    id: 3,
    firstName: "Maya",
    lastName: "Sharma",
    email: "jane.smith@example.com",
    gender: "Female",
    role: "User",
    disabled: false,
  },
  {
    id: 4,
    firstName: "Rabin",
    lastName: "Panta",
    email: "jane.smith@example.com",
    gender: "Female",
    role: "User",
    disabled: false,
  },
  {
    id: 5,
    firstName: "Diki",
    lastName: "Sherpa",
    email: "jane.smith@example.com",
    gender: "Female",
    role: "User",
    disabled: false,
  },
  {
    id: 6,
    firstName: "Bimala",
    lastName: "Bhandari",
    email: "jane.smith@example.com",
    gender: "Female",
    role: "User",
    disabled: false,
  },

  // Add more user objects as needed
];

const UserTable = ({ userList }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 30));
    setPage(0);
  };

  const convertToCSV = (dataArray) => {
    const header = Object.keys(dataArray[0]).join(",");
    const rows = dataArray
      .map((obj) => Object.values(obj).join(","))
      .join("\n");
    return `${header}\n${rows}`;
  };

  const handleDownload = () => {
    // Convert data to CSV format
    const csvData = convertToCSV(userList);

    // Create a Blob object for the CSV content
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="">
      <div className="flex justify-between mb-[40px] items-center">
        <h4 className="font-bold  textSecondary">Users</h4>
        <button
          className="px-[26px] py-[10px] text-white rounded-[10px] bg-primaryColor hover:bg-hoverPrimary flex gap-2"
          onClick={handleDownload}
        >
          Download CSV <ArrowDownwardIcon />
        </button>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={headCell.align}>
                  {headCell.label}
                </TableCell>
              ))}
              {/* <TableCell align="right">Actions</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {userList
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell padding="checkbox">
                    {/* <Checkbox color="primary" /> */}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate(`/admin-user/${user.id}`);
                    }}
                    className="cursor-pointer"
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                      <Box ml={2}>
                        <Typography variant="subtitle1">
                          {`${user.lastName} ${user.firstName}`}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                          {user.email}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="center">{user.userName}</TableCell>
                  <TableCell align="center">{user.role}</TableCell>
                  <TableCell align="center">
                    <Chip
                      color="primary"
                      label={user?.status}
                      className={`${
                        user?.status === "active" ? "!bg-greenC" : "!bg-red"
                      }`}
                    />
                  </TableCell>
                  {/* <TableCell align="right">
                    <IconButton aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default UserTable;
