import React, { useState } from "react";
import Logo from "../../assets/images/RecantNewLogo.png";
import WhatsAppLogo from "../../assets/images/WhatsAppImage.png";
import PhoneImage from "../../assets/images/PhoneImage.png";
import EmailImage from "../../assets/images/emailImage.png";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ToggleSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const svgStyle = {
    width: "17px",
    height: "17px",
    transition: "transform 0.3s ease",
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    transformOrigin: "center",
  };

  return (
    <div className="w-full max-w-md">
      <div
        onClick={toggleOpen}
        className="flex items-center justify-between cursor-pointer bg-blue-500 text-baseColor rounded-lg p-2"
      >
        <div className="text-sm font-semibold">{title}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg
            style={svgStyle}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
      </div>
      {isOpen && <div className="text-sm">{children}</div>}
    </div>
  );
};

const Footer = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(1024));
  const navigate = useNavigate();
  const handleClick = () => {
    const phoneNumber = "61480494834";
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };
  return (
    <div className="  cursor-pointer bg-[#e0e0e0]">
      <div className=" max-w-[1440px] mx-auto  px-[15px] lg:px-[60px] md:!px-[90px]">
        <div className="lg:py-16">
          <div
            className={`flex flex-col justify-between py-[50px] lg:!flex-row lg:!gap-12 gap-6 items-center`}
          >
            <div className="w-[150px] lg:w-[190px] mb-4">
              <img
                src={Logo}
                alt="logo"
                className=""
                onClick={() => navigate("/")}
              />
            </div>

            <div className="flex gap-[39px] lg:!gap-24">
              <div className="flex flex-col lg:items-center">
                <div className="text-base mb-2 lg:!text-lg font-DMSans font-bold">
                  Connect With Us
                </div>
                <div className="flex flex-col gap-2 font-zain lg:!text-lg font-[300] ">
                  <div className="flex gap-1 items-center hover:underline">
                    <div className="w-[15px]">
                      <img src={PhoneImage} alt="phone" className="" />
                    </div>
                    +61480494834
                  </div>
                  <div className="flex gap-1 items-center hover:underline">
                    <div className="w-[15px]">
                      <img src={EmailImage} alt="email" className="" />
                    </div>
                    cam@recant.ai
                  </div>
                </div>
              </div>
              <div>
                <div className="text-base mb-2 lg:!text-lg font-DMSans font-bold">
                  Policies
                </div>
                <button
                  onClick={() => navigate("/privacy-policy")}
                  className="hover:underline font-zain lg:!text-lg font-[300] "
                >
                  Privacy Policy
                </button>
              </div>
              <div className="w-[20px]">
                <img
                  src={WhatsAppLogo}
                  alt="whatsapp"
                  className=""
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pb-[15px] text-lg text-[#223443] font-[300] font-zain lg:!text-xl flex items-center justify-center">
          © 2024 Recant.ai All rights reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
