import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { apiClient } from "../../config/axios";
import RangeSlider from "../../common/RangeSlider";
import PlainHeader from "../../common/PlainHeader";
import ButtonField from "../../common/ButtonField";
import "../../components/Auth/SignUp/Signup.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

const SensitivityParameter = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const [sliders, setSliders] = useState([
    { label: "Sexually suggestive or explicit content.", value: 1 },
    { label: "Threatening or violent content.", value: 1 },
    { label: "Racism", value: 1 },
    { label: "Sexism", value: 1 },
    { label: "General Hate", value: 1 },
  ]);

  useEffect(() => {
    const markFirstLogin = async () => {
      try {
        const response = await apiClient.put(`/api/user/first-login`, {
          firstLogin: false,
        });
        console.log(response, "First login marked");
      } catch (error) {
        console.error("Failed to mark first login", error);
      }
    };

    const fetchUserData = async () => {
      try {
        const response = await apiClient.get(`/api/user`);
        const userData = response.data;

        if (userData.categories && userData.categories.length > 0) {
          setSliders(userData.categories);
        }
      } catch (error) {
        console.error("Failed to fetch user data", error);
      }
    };

    markFirstLogin();
    fetchUserData();
  }, []);

  const onSetParameterSubmit = async () => {
    try {
      const response = await apiClient.put(`/api/user`, {
        categories: sliders,
      });
      console.log(response, "response");
      toast.success("Sensitivity parameters updated successfully");
      navigate("/dashboard");
    } catch (error) {
      console.error("Update failed", error);
    }
  };

  const handleSliderChange = (index, newValue) => {
    const updatedSliders = sliders.map((slider, i) =>
      i === index ? { ...slider, value: newValue } : slider
    );
    setSliders(updatedSliders);
  };

  return (
    <>
      <PlainHeader />
      <div className="signup-container pt-28 lg:!pt-16">
        <div className="login-wrapper">
          <form className="" onSubmit={handleSubmit(onSetParameterSubmit)}>
            <div className="">
              <div className="flex flex-col  mt-2">
                <div className=" h-[100%]">
                  <div className="flex flex-col gap-1 ">
                    <div className=" mb-3">
                      Please select your overall sensitivity level towards the
                      following subjects.
                    </div>
                    <div className="flex flex-col gap-4">
                      {sliders.map((slider, index) => (
                        <div
                          key={index}
                          className="flex flex-col relative gap-1"
                        >
                          <div>{slider.label}</div>
                          <RangeSlider
                            className="w-full text-primaryColor"
                            color={"#0A2B38"}
                            tooltipPlacement="top"
                            min={1}
                            max={10}
                            value={slider.value}
                            onChange={(e) =>
                              handleSliderChange(index, e.target.value)
                            }
                          />{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ButtonField label={"Submit"} />
          </form>
        </div>
      </div>
    </>
  );
};

export default SensitivityParameter;
